$base-color: #383838;
$border-dark: rgba($base-color, 0.88);
$border-light: #dee2e6;
$primary-bg: #3498db;
$navbar-bg: $siteThemeColor;
$white: #fff;
$offWhite: #ececec;
$black: #000;
$menuItemColor: #dcdcdc;
$menuItemSize: 16px;
$borderColor: rgba(0, 0, 0, 0.125);
$transparentBlack: rgba(0, 0, 0, 0.05);
$sortingArrowColor: #555;
$accentColor: #6c757d;
$greenOutline: green;
$redOutline: red;
$orange: orange;
$selected: lighten(
  $color: $siteThemeColor,
  $amount: 5
);
$disabled: #ccc;
$hover: $selected;
$boxShadow: rgba(0, 0, 0, 0.25);
$formBorderColor: #ced4da;
$formInputColor: #495057;
$grey: #e2e2e2;
$toggleBall: $siteThemeColor;
$midGray: #8e8e8e;
$read-only: #e9ecef;

//  Breakpoints
$breakpoint-tablet: 768px;
$breakpoint-desktop: 991px;
$breakpoint-mobile: 480px;

// Rules
$i: !important;

$theme-colors: (
  "primary": $siteThemeColor,
  /*"success": #0AA753, 
    "warning": #F39C12, 
    "danger": #E74C3C */
);
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$theme-colors: (
  "primary": $siteThemeColor,
  "success": #0aa753,
  "warning": #f39c12,
  "danger": #e74c3c
);
$colors: (
  "green": #0cc964,
  "edge": #00caff,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900
);
// $enable-rounded: false;
$primary: $siteThemeColor;
