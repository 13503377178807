#container {
  position: relative;
  text-align: center;
  max-width: 80%;
  box-sizing: border-box;
  margin: 0 auto;

  .buttons {
    position: absolute;
    top: 180px;
    text-align: center;
    width: 100%;
  }
  .btn {
    width: 50px;
    height: 30px;
  }
  .step-wizard {
    display: inline-block;
    position: relative;
    width: 100%;
    .progress {
      position: absolute;
      top: 15px;
      left: 12.5%;
      width: 75%;
    }
    .progressbar {
      position: absolute;
      background-color: #3b5343;
      opacity: 1;
      height: 3px;
      //border: 1px solid e5e6e6;
      width: 0%;
      -webkit-transition: width 0.6s ease;
      -o-transition: width 0.6s ease;
      transition: width 0.6s ease;
      &.empty {
        opacity: 1;
        width: 100%;
        background-color: #d0d0d0;
      }
    }
    ul {
      //   position: absolute;
      width: 100%;
      list-style-type: none;
      padding: 0;
      left: -2%;
    }
    li {
      display: inline-block;
      text-align: center;
      width: 15%;
      & .step {
        text-align: center;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid;
        border-color: #d0d0d0;
        background: #ffffff;
        -webkit-transition: background-color 0.6s ease, border-color 0.6s ease;
        -o-transition: background-color 0.6s ease, border-color 0.6s ease;
        transition: background-color 0.6s ease, border-color 0.6s ease;
      }
      & .title {
        text-decoration: underline;
        width: 100%;
        padding-top: 10px;
        color: #767676;
        -webkit-transition: color 0.6s ease;
        -o-transition: color 0.6s ease;
        transition: color 0.6s ease;
      }
      &.active {
        .step {
          //   border-color: #3b5343;
          color: #3b5343;
        }
        .title {
          text-decoration: none;
          color: #3b5343;
          font-weight: bold;
        }
      }
      &.done .title {
        color: #3b5343;
        &:hover {
          color: #3b5343;
        }
      }
      &.done .step {
        color: white;
        background-color: #3b5343;
        border-color: #3b5343;
      }
      &.rejected .step {
        color: white;
        background-color: red;
        border-color: red;
      }
      & > button {
        background: none;
        border: none;
        display: block;
        width: 100%;
        color: #777;
        position: relative;
        text-align: center;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .step-wizard li {
    width: 24%;
  }
}

@media only screen and (max-width: 375px) {
  .step-wizard li {
    width: 22%;
  }
}

li.done button div.step,
li.rejected button div.step {
  font-size: 0 !important;
}
li.done button div.step::before {
  content: "\2713 ";
  font-size: 18px;
}

li.rejected button div.step::before {
  content: "\2715 ";
  font-size: 18px;
}

.progress-header {
  font-size: 20px;
  margin: 15px 0;
  font-family: "Roboto", sans-serif;
}
