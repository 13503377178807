@import "../../resources/scss/variables.scss";

.has-error {
  .form-control,
  button[class^="css-"],
  .user-selectbox > div,
  .ck.ck-editor__main > .ck-editor__editable,
  [class*="-control"],
  .ck.ck-toolbar {
    border-color: $redOutline $i;
  }
}

.help-block {
  color: $redOutline;
  font-size: 13px;
}
