@import "../../resources/scss/variables.scss";

.accent-line:after,
.accent-line:before {
  background: linear-gradient(180deg, $accentColor, $accentColor) no-repeat 50%/98%
    0.05em;
  content: "";
  display: table-cell;
  flex: 2;
}

.accent-content {
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  align-items: center;
  img {
    max-width: 200px;
    max-height: 100px;
  }
}

.accent-line {
  &.left {
    &:before {
      display: none;
    }
  }
}
