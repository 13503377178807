.multiselect-container {
  button {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: none;
    padding: 5px 8px 5px 8px;
    > span:empty:first-child:before {
      content: "1 selected";
    }
    + div {
      width: 100%;
      border: 1px solid #ced4da;
      box-shadow: none;
    }
  }
}
