@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");
@import "./variables.scss";
@import "bootstrap/scss/bootstrap.scss";

@import "./sideBtn.scss";

html {
  font-size: 14px;
  @media (min-width: $breakpoint-tablet) {
    font-size: 16px;
  }
}

body {
  font-family: "Roboto", sans-serif $i;
  font-weight: 300;
  background-image: url("../images/background.JPG");
  background-color: #f0f0f0 $i;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

h1 {
  font-family: "Roboto Slab", serif;
  font-size: 2.5rem;
  margin-bottom: 0rem;
  padding: 0.75rem 0rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto Slab", serif;
}

.roboto {
  font-family: "Roboto Slab", serif;
}

.monofont {
  font-family: "Lucida Console";
}
/* Since Bootstrap won't supply btn colors for all of thier definitions, we will :) */
@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $colors {
  .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $white);
    }
  }
}

@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value, $ignore-warning: true);
}

.flex {
  display: flex;
}

.flex-one {
  flex: 1;
}
.flex-two {
  flex: 2;
}
.flex-three {
  flex: 3;
}

.flex-four {
  flex: 4;
}

.flex-column {
  flex-direction: column;
}

.flex-align {
  justify-content: center;
  align-items: center;
}

.flex-vertical-align {
  align-items: center;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.verticle-flex-end {
  align-items: flex-end;
}

.center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.align-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}

.hide {
  display: none $i;
}
.mr-5 {
  margin-right: 5px $i;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mtb-10 {
  margin: 10px 0;
}
.margin-right-20 {
  margin-right: 20px;
}
.mr-20 {
  margin: 20px 0;
}
.mt-20 {
  margin-top: 20px;
}
.mt-50 {
  margin-top: 50px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px $i;
}
.ml-25 {
  margin-left: 25px;
}
.rs-5 {
  margin-right: 5px;
}
.label {
  margin-bottom: 0.5rem;
}
.gap50 {
  margin-bottom: 50px;
}

.padding-20 {
  padding: 20px;
}

.no-padding {
  padding: 0 $i;
}

.center-align {
  text-align: center;
}
.right-align {
  text-align: right;
}
.inline-block {
  display: inline-block;
}
.box-shadow {
  box-shadow: 0 0.25rem 0.75rem $transparentBlack;
}
.separator {
  margin: 0 15px;
  border-left: 2px solid $disabled;
}
.width-20px {
  width: 20px;
  display: inline-block;
}
.caption {
  background: $grey;
  p {
    margin: 0;
    font-size: 17px;
  }
}
.invalid {
  border: 1px solid $redOutline;
}
.html-icon {
  font-size: 5em;
}
button {
  svg {
    margin-right: 5px;
  }
}
.nav-link {
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    background: $disabled $i;
  }
}

.nested-tab {
  border-left: 1px solid $border-light;
  border-right: 1px solid $border-light;
  border-bottom: 1px solid $border-light;
  background: $white;
  padding: 10px 10px 0 10px;
}

input[disabled] {
  background: $disabled;
}

.form-control {
  &.has-error {
    border: 1px solid $redOutline;
  }
}

.file-icon,
.folder-icon {
  min-width: 16px;
  display: inline-block;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  > li {
    &.folder-item {
      cursor: pointer;
    }
    > span {
      margin-right: 10px;
    }
  }
}

.button-primary,
.btn-primary,
.btn-secondary {
  background: $siteThemeColor $i;
  border: darken($color: $siteThemeColor, $amount: 20) $i;
  padding: 5px 10px;
  color: $white;
  border-radius: 5px;

  &:hover {
    background: lighten($color: $siteThemeColor, $amount: 5) $i;
    color: lighten($color: $white, $amount: 5) $i;
  }
}

.actions {
  a {
    display: inline;
    margin-right: 10px;
    padding: 0;
    color: $siteThemeColor;
    &:hover {
      cursor: pointer;
    }
  }
}

.root-breadcrumb {
  font-size: 18px;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fullwidth {
  width: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  z-index: 1;
}

.right {
  right: 0;
}

.top {
  top: 0;
}

.edit {
  padding: 0;
  color: $siteThemeColor;
  &:hover {
    color: darken($color: $siteThemeColor, $amount: 50);
  }
}

.panel {
  background: $white;
  border: 1px solid $border-light;
  box-shadow: 0 2px 5px $boxShadow;
  border-radius: 5px;
  margin-bottom: 30px;
  .panel-header {
    border-bottom: 2px solid $border-light;
    background: $offWhite;
    padding: 10px $i;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: bold;
    align-items: center;
    display: flex;
  }
  .panel-body {
    padding-left: 10px $i;
    padding-right: 10px $i;
  }
}

.panel-separator {
  border-right: 1px solid $border-light;
}

.panel-padding {
  padding: 10px;
}

.translate.disabled {
  display: none;
}

.flex-basis-5 .form-group {
  flex-basis: 10%;
}

.flex-basis-3 .form-group {
  flex-basis: 33%;
}

.flex-basis-2 .form-group {
  flex-basis: 49%;
}

.table {
  &.custom-table {
    margin-bottom: 0 $i;
  }
  &.table-sm {
    tr {
      td,
      th {
        border-top: 0 $i;
        border-bottom: 1px solid $borderColor $i;
      }
      &:last-child {
        td,
        th {
          border-bottom: 0 $i;
        }
      }
    }
  }
  .sortable {
    .order {
      margin-left: 5px;
      .dropdown {
        .caret {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 8px solid $sortingArrowColor;
          top: -1px;
          position: absolute;
        }
      }
      .dropup {
        .caret {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 8px solid $sortingArrowColor;
          top: -1px;
          position: absolute;
        }
      }
    }
  }
}

.align-loader {
  justify-content: left;
  align-items: center;
  > div {
    margin: 0;
  }
}

.ck-read-only {
  background: $read-only $i;
}

.disabled-selectbox > div {
  background: $read-only $i;
  border-color: $formBorderColor $i;
  * {
    color: $black;
  }
}

table {
  font-size: 0.9em;
  box-shadow: 0 2px 5px $boxShadow;
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  thead,
  .thead {
    font-weight: bold;
    background: $offWhite;
  }
  td,
  th {
    padding: 1em 0.5em;
    vertical-align: middle $i;
  }
  td {
    border-bottom: 1px solid $transparentBlack;
    background: $white;
  }
}

.react-bootstrap-table {
  .react-bootstrap-table-sort-order {
    position: relative;
    .caret {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid $sortingArrowColor;
      top: -1px;
      position: absolute;
    }
    &.dropup {
      .caret {
        border-bottom: 8px solid $sortingArrowColor;
        border-top: 0;
      }
    }
  }
  td:not(.expand-cell) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  // td {
  //   border-bottom: 1px solid $transparentBlack;
  //   background: $white;
  // }
}

.react-datepicker-wrapper {
  display: block $i;
  .react-datepicker__input-container {
    display: block;
  }
}

.react-datepicker__input-time-container {
  input[type="time"] {
    font-size: 13px;
    border-radius: 4px;
    box-shadow: inset 0 2px 2px #e9e9e9;
    border: 1px solid #aeaeae;
    line-height: 16px;
    padding: 6px 10px 5px;
    width: 100% $i;
  }
}

.auto-layout {
  table {
    table-layout: auto $i;
  }
}

.recordID {
  overflow: visible $i;
  white-space: normal $i;
  text-overflow: unset $i;
  font-family: "Lucida Console";
}

.app-icon {
  color: $siteThemeColor;
}

.outline {
  color: $base-color;
  border: 1px solid $border-dark;
  border-radius: 5px;
  padding: 5px 10px $i;
  transition: all 0.2s ease;
  &:hover {
    color: $white;
    background: lighten($color: $base-color, $amount: 25) $i;
  }
}

.greenOutline {
  border-color: $greenOutline $i;
  color: $greenOutline $i;
  &:hover {
    background: darken($color: $greenOutline, $amount: 1) $i;
    color: $white $i;
  }
}
.redOutline {
  border-color: $redOutline $i;
  color: $redOutline $i;
  &:hover {
    background: lighten($color: $redOutline, $amount: 25) $i;
    color: $white $i;
  }
}

.red {
  color: $redOutline;
}

.row {
  margin-bottom: 20px;
}

.squareBtn {
  border-radius: 0 $i;
}

a:hover {
  cursor: pointer $i;
}

.bold {
  font-weight: bold;
}

.white-background {
  background: $white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px $boxShadow;
}

.dashboard-chart {
  width: 50%;
  margin: 20px auto 0;
}

.capitalize {
  text-transform: capitalize;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.98);
  z-index: 99990;
}
.loader > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.loader > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 150px;
  height: 150px;
}

.react-datepicker-popper {
  z-index: 9999 $i;
}

.react-datepicker--time-only {
  min-width: 120px $i;
  .react-datepicker__time-container {
    min-width: 120px $i;
    .react-datepicker__time .react-datepicker__time-box {
      min-width: 120px $i;
    }
  }
}

#description {
  font-size: inherit $i;
}

.height-100 {
  min-height: 100px;
}

.hide {
  display: none;
}

.info-circle {
  background: transparent $i;
  color: lighten($color: $siteThemeColor, $amount: 10) $i;
  padding: 0 $i;
  font-size: 16px $i;
  min-width: auto $i;
  margin-left: 5px;
  &:focus {
    outline: none;
  }
}

.attachment-input {
  background: $white;
  padding: 4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $formInputColor;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $formBorderColor;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.attachment {
  display: block;
  background: $white;
  padding: 6px;
  border: 1px solid $formBorderColor;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
}

.react-bootstrap-table-pagination {
  .react-bs-table-sizePerPage-dropdown {
    float: left;
  }
  #pageDropDown {
    background: $white $i;
    color: $border-dark;
    border: 1px solid $borderColor $i;
    &:hover {
      color: $border-dark $i;
    }
  }
  .pagination {
    justify-content: flex-end;
  }
  .dropdown-menu {
    top: 30px $i;
    .dropdown-item {
      padding: 0;
      a {
        display: block;
        padding: 0.25rem 1.5rem;
      }
    }
  }
  .page-item {
    &.active {
      .page-link {
        background-color: $siteThemeColor;
        border-color: $siteThemeColor;
        color: $white;
      }
    }
    .page-link {
      color: $border-dark;
    }
  }
}

.image-container {
  position: relative;
  border: 1px solid $borderColor;
  border-radius: 5px;
  padding: 2px 10px;
  background: $transparentBlack;
  .remove-image {
    position: absolute;
    top: -11px;
    right: -10px;
    background: $white;
    border-radius: 50%;
    padding: 0px 6px;
    font-size: 14px;
    cursor: pointer;
  }
}

.image-max-width {
  max-width: 190px;
  max-height: 50px;
}

.export-btn {
  background: var(--info);
  color: $white;
  padding: 0 15px;
  margin-right: 10px;
  &:hover {
    color: $white;
  }
}

.nav-link {
  span {
    &:focus {
      outline: none;
    }
  }
}

.tab-content {
  .nav-link {
    position: relative;
  }
}

.count-badge {
  font-size: 12px;
  background: $redOutline;
  border-radius: 50%;
  color: $white;
  font-weight: bold;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  line-height: 20px;
  top: -6px;
  right: -8px;
}

.orange-bg {
  background-color: $orange $i;
  color: $white $i;
}

.green-bg {
  background-color: $greenOutline $i;
  color: $white $i;
}

.red-bg {
  background-color: $redOutline $i;
  color: $white $i;
}

@media (max-width: $breakpoint-tablet) {
  h1 {
    font-size: 1.8rem $i;
  }
  h4 {
    font-size: 1.2rem $i;
  }
  .table td,
  .table th {
    padding: 0.3rem;
    vertical-align: middle $i;
  }
}

@media (min-width: $breakpoint-tablet) {
  .modal-dialog {
    max-width: none $i;
    min-width: 500px;
    display: flex;
    .modal-content {
      min-width: 500px;
      max-width: 1000px;
      margin: 0 auto;
      width: auto;
    }
  }
}

.react-datepicker__navigation {
  background: none $i;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q,
.sv_main .sv_container .sv_body .sv_p_root table {
  overflow: initial $i;
}

.search-toggle-btn {
  width: 50px;
  background-color: $disabled;
  height: 25px;
  border-radius: 20px;

  position: relative;
  cursor: pointer;
  .ball {
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 50%;
    background: $midGray;
    position: absolute;
    left: 0;
    transition: all 0.5s ease;
    &.toggled {
      left: calc(100% - 25px);
      // background: #157112;
      background: $toggleBall;
    }
  }
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem $i;
  text-align: center $i;
  cursor: pointer $i;
  position: absolute $i;
  top: 10px $i;
  width: 0 $i;
  padding: 0 $i;
  border: 0.45rem solid transparent $i;
  z-index: 1 $i;
  height: 10px $i;
  width: 10px $i;
  text-indent: -999em $i;
  overflow: hidden $i;
  min-width: auto $i;
}

.react-datepicker__navigation--next {
  border-left-color: $black $i;
}

.react-datepicker__navigation--previous {
  border-right-color: $black $i;
}

/* Main headings */
.h1,
h1 {
  font-size: 1.8rem;
}

/* Dynamic Fields */
.dynamic-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-group {
    flex: 1 0 30%;
    margin-right: 1%;
    &.full-flex {
      flex-basis: 100%;
    }
  }
}

.block {
  display: block;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-switch {
  padding-left: 0;
  input[type="checkbox"].toggle {
    opacity: 0;
    position: absolute;
    left: -99999px;
  }
  input[type="checkbox"].toggle + label {
    height: 40px;
    line-height: 40px;
    background-color: #ccc;
    padding: 0px 10px;
    border-radius: 25px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    -moz-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    -webkit-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    -moz-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
  }
  input[type="checkbox"].toggle + label:before,
  input[type="checkbox"].toggle + label:hover:before {
    content: " ";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 36px;
    height: 36px;
    background: #fff;
    z-index: 2;
    -moz-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    -webkit-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    -moz-border-radius: 14px;
    -webkit-border-radius: 14px;
    border-radius: 50%;
  }
  input[type="checkbox"].toggle + label .off,
  input[type="checkbox"].toggle + label .on {
    color: #fff;
  }
  input[type="checkbox"].toggle + label .off {
    margin-left: 46px;
    display: inline-block;
  }
  input[type="checkbox"].toggle + label .on {
    display: none;
  }
  input[type="checkbox"].toggle:checked + label .off {
    display: none;
  }
  input[type="checkbox"].toggle:checked + label .on {
    margin-right: 41px;
    display: inline-block;
  }
  input[type="checkbox"].toggle:checked + label,
  input[type="checkbox"].toggle:focus:checked + label {
    background-color: $siteThemeColor;
  }
  input[type="checkbox"].toggle:checked + label:before,
  input[type="checkbox"].toggle:checked + label:hover:before,
  input[type="checkbox"].toggle:focus:checked + label:before,
  input[type="checkbox"].toggle:focus:checked + label:hover:before {
    background-position: 0 0;
    top: 2px;
    left: 100%;
    margin-left: -38px;
  }
}

.DisplayCompanyNew {
  text-align: center;
  left: 0px;
  color: #000;
  width: 100%;
  background: lightgray;
}

.DisplayCompany {
  position: fixed;
  text-align: end;
  right: 15px;
  color: darkgray;
}

.disabled-row {
  background-color: #c67a00;
  td {
    background-color: #c67a00;
    color: #e7e7e7;
  }
}

.survey-custom-btn {
  min-width: 100px;
  border: none $i;
  border-radius: 0;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 24px;
}

.survey-wizard-select{
  width: 280px;
}

.btns-container {
  padding: 0 1em;
}

.form-heading {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.steps-container > .form-row > div:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: 13px;
  border-top: 15px solid grey;
  left: calc(50% - 10px);
}

.steps-container > .form-row > div:last-child:after {
  border: none;
}

.step-count {
  background: $redOutline;
  color: $white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
  font-size: 16px;
}

#apiType {
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
}
#apiURL {
  border-radius: 0 0.25rem 0.25rem 0;
}
#apiType:focus,
#apiURL:focus {
  box-shadow: none;
  border-color: $formBorderColor;
}
textarea#apiBody {
  min-height: 200px;
}

.bottom-border {
  border-bottom: 1px solid $formBorderColor;
}

.collapsed {
  display: none;
}

.info-btn {
  background: var(--info);
  color: $white;
  margin-right: 10px;
  &:hover {
    color: $white;
  }
}

.user-image-cell {
  min-height: 100px;
}

.vertical-table {
  font-size: 0.9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: $white;
  .table-header {
    > div {
      display: flex;
      align-items: center;
      font-weight: bold;
      &.image-cell {
        height: 75px;
        visibility: hidden;
      }
    }
  }
  .flex-column {
    > div {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }
    &.table-columns {
      > div {
        flex: 1;
      }
    }
  }
}

.claims-table {
  td {
    padding: 0.4em 1.2em;
    margin-bottom: 0;
  }
  th {
    padding: 1.2em;
    margin-bottom: 0;
  }
}

.stv-radio-buttons-wrapper {
  clear: both;
  display: flex;
  span {
    flex: 1;
    background-color: #a1a1a1;
    &:first-of-type {
      border-radius: 0.7em 0 0 0.7em;
      border-right: 1px solid #696969;
    }
    .stv-radio-button {
      // position: absolute;
      // left: -9999em;
      // top: -9999em;
      display: none;

      & + label {
        padding: 0.3em 1em;
        cursor: pointer;
        color: #fff;
        background-color: #a1a1a1;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        // &:last-of-type {
        //   border-radius: 0 0.7em 0.7em 0;
        // }
      }

      &:checked + label {
        background-color: #3277b3;
      }
    }
  }
  &.three {
    span {
      &:last-of-type {
        border-left: 1px solid #696969;
      }
    }
  }
}

.primary-container {
  margin: 0.5rem 0;
  padding: 1rem;
  background: #f4f4f4;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.15);
  > div {
    padding: 1rem;
    background: #e9e7e7;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.15);
    margin: 0 1rem 20px;
  }
  .controls-group {
    margin-bottom: 20px;
  }
}

.delete-btn {
  background: #ed1919 $i;
  &:hover {
    background: #ed1919 $i;
  }
}

.days-icon{
  border-radius: 50%;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.days-icon-active{
  background-color: cornflowerblue;
}

.days-icon-inactive{
  background-color: darkgray;
}

.days-icon:hover{
  background-color: deepskyblue;
}
.max-width-466{
  max-width: 466px ;
}

.color-cornflowerblue{
  color: cornflowerblue;
}

.f-12{
  font-size: 12px;
}

table .nav-link {
  padding: 0;
}
.tippy-box[data-theme~='approved'] {
  background-color: var(--green);
  color: white;
}
.RETablerow{
  border-radius: 4px;
}
iframe {
  pointer-events: none;
}

.select-none {
  user-select: none;
}
.cursor-pointer {
  cursor: pointer;
}