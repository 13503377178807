@import "../../resources/scss/variables.scss";

#main {
  width: 750px;
  margin: 0 auto;
}

#sidebar {
  float: right;
  width: 100px;
  display: none;
}

#sequence {
  width: 100%;
  height: 70px;
}

#legend {
  padding: 10px 0 0 3px;
}

#sequence text,
#legend text {
  font-weight: 600;
  fill: #fff;
}

#chart {
  position: relative;
}

#chart path {
  stroke: #fff;
}

#explanation,
#info-container {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #666;
  // z-index: -1;
  transform: translate(-50%, -50%);
  font-size: 13px;
  max-width: 280px;
  min-width: 280px;
}

.tagSearch {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: left;
}

#percentage,
#info-container > .title {
  font-size: 1.5em;
  font-family: Roboto Slab, serif;
  font-weight: 700;
}

#description {
  font-size: 12px;
}

#departmentLogo {
  font-size: 30px;
}

#sunburst {
  padding-top: 10px;
  margin: auto;
}
@media (max-width: 800px) {
  #sunburst {
    display: none;
  }
}
@media (min-width: 800px) {
  #sunburst {
    display: flex;
  }
}
#main {
  margin: auto;
}
#sequence {
  height: 50px;
}
#legend {
  padding: 10px 0 0 3px;
}
#legend text,
#sequence text {
  font-size: 12px;
  font-weight: 500;
  fill: #fff !important;
}
#chart {
  position: relative;
}
#chart path {
  stroke: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
#center {
  border-radius: 50%;
  height: 340px;
  width: 340px;
  position: absolute;
  top: 180px;
  left: 255px;
  text-align: center;
  color: #666;
  padding-top: 30px;
  visibility: hidden;
}
#center.active {
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  visibility: visible;
}
#center.deactive {
  -webkit-animation: fadeout 0.3s ease-out 0s forwards;
  animation: fadeout 0.3s ease-out 0s forwards;
}
#center .departmentLogo {
  margin: 0 100px;
}
#center .tagSearch {
  margin-top: 90px;
}
#center .tagSearch .taginputtitle {
  font-family: Roboto Slab, serif;
  font-size: 16px;
  font-weight: 500;
  padding: 3px;
  margin: 0 40px;
}
#center .tagSearch .taginput {
  margin: 0 10px;
}
#center .department {
  font-size: 20px;
  padding: 3px;
  min-height: 28px;
  margin: 0 40px;
}
#center .activity,
#center .department {
  font-family: Roboto Slab, serif;
  font-weight: 700;
}
#center .activity {
  font-size: 16px;
  padding: 2px;
  min-height: 22px;
  margin: 0 30px;
}
#center .process {
  font-size: 12px;
  font-weight: 700;
  padding: 2px;
  min-height: 18px;
  margin: 0 25px;
}
#center .description {
  font-size: 12px;
  font-weight: 700;
  padding: 4px;
  min-height: 22px;
  margin: 0 20px;
}
.select2-container {
  width: 300px;
  font-size: 14px;
  text-align: left;
}
#modal,
#modalFill {
  position: absolute;
  top: 10px;
  left: 40px;
  width: 670px;
  height: 580px;
  text-align: center;
  color: #666;
  z-index: 100;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}
#modal.active,
#modalFill.active {
  -webkit-animation: fadein 0.5s ease-in 0.2s forwards;
  animation: fadein 0.5s ease-in 0.2s forwards;
  visibility: visible;
}
#modal.deactive,
#modalFill.deactive {
  -webkit-animation: fadeout 0.3s ease-out 0s forwards;
  animation: fadeout 0.3s ease-out 0s forwards;
}
#modal .activity,
#modalFill .activity {
  font-family: Roboto Slab, serif;
  font-size: 1.5rem;
  font-weight: 500;
  width: 505px;
  margin: auto;
}
#modal .department,
#modalFill .department {
  font-family: Roboto Slab, serif;
  font-size: 1.25rem;
  font-weight: 500;
}
#modal .details,
#modalFill .details {
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  width: 600px;
  height: 360px;
  overflow: auto;
}
#modal .details > div,
#modalFill .details > div {
  display: none;
}
#modal .details .title,
#modalFill .details .title {
  font-family: Roboto Slab, serif;
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
#modal .details > div.active,
#modalFill .details > div.active {
  height: 100%;
  display: block;
}
.dpoa-disabled {
  color: rgba(0, 0, 0, 0.2);
}
.dpoa-disabled path,
.dpoa-disabled span {
  cursor: not-allowed !important;
}
#modalFill .button span {
  cursor: pointer;
  font-size: 48px;
}
#modalFill .button.active span {
  color: #3498db;
}
#modalFill .button.active span:hover {
  color: #2384c6;
}
#modalFill .button.edit {
  position: absolute;
  left: 265px;
  bottom: 290px;
}
#modalFill .button.remove {
  position: absolute;
  left: 360px;
  bottom: 290px;
}
#modal .button span {
  cursor: pointer;
  font-size: 35px;
}
#modal .button.active span {
  color: #3498db;
}
#modal .button.active span:hover {
  color: #2384c6;
}
#modal .button.exit span {
  font-size: 46px;
}
#modal .button.edit {
  position: absolute;
  left: 265px;
  bottom: 21px;
}
#modal .button.remove {
  position: absolute;
  left: 487px;
  bottom: 61px;
}
#modal .button.info {
  position: absolute;
  left: 315px;
  bottom: 6px;
}
#modal .button.files {
  position: absolute;
  left: 432px;
  bottom: 32px;
}
#modal .button.report {
  position: absolute;
  left: 378px;
  bottom: 17px;
}
#modal .button.notifications {
  position: absolute;
  left: 165px;
  bottom: 66px;
}
#modal .button.assets {
  position: absolute;
  left: 432px;
  bottom: 32px;
}
#modal .button.flow {
  position: absolute;
  left: 216px;
  bottom: 37px;
}
[data-title] {
  font-size: 30px !important; /*optional styling*/
  position: relative;
  cursor: help;
}

.annual-cycle {
  #modal {
    .button.remove {
      left: 373px;
      bottom: 13px;
    }
    .button.edit {
      left: 260px;
      bottom: 14px;
    }
    .button.info {
      left: 311px;
      bottom: -5px;
      > span {
        font-size: 38px $i;
      }
    }
  }
}

#freshwidget-button:nth-child(2) {
  margin-top: 90px;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  top: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
}
[data-title]:hover::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  // border-top: 8px solid #000;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@media screen and (min-height: 10px) {
  #sunburst,
  .select12-container {
    zoom: 0.5;
  }
}
@media screen and (min-height: 525px) {
  #sunburst,
  .select12-container {
    zoom: 0.6;
  }
}
@media screen and (min-height: 600px) {
  #sunburst,
  .select12-container {
    zoom: 0.7;
  }
}
@media screen and (min-height: 675px) {
  #sunburst,
  .select12-container {
    zoom: 0.8;
  }
}
@media screen and (min-height: 750px) {
  #sunburst,
  .select12-container {
    zoom: 0.9;
  }
}
@media screen and (min-height: 825px) {
  #sunburst,
  .select12-container {
    zoom: 1;
  }
}
