@import "../../resources/scss/variables.scss";

.admin-view {
  .admin-title {
    margin: 10px 0;
  }
  .tiles-container {
    .tile {
      @media (max-width: $breakpoint-tablet) {
        margin-right: 0;
        margin-bottom: 5%;
      }
    }
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }
}

.parent-admin-view {
  .react-bootstrap-table {
    .nav-link {
      padding: 0;
      color: $siteThemeColor;
    }
  }
}
