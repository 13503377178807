@import "../../resources/scss/variables.scss";

.nav-pills {
  .nav-link {
    &.active {
      background: $formBorderColor;
      &:hover {
        color: $white;
      }
    }
  }
}

.large {
  font-size: 18px;
}

.input {
  margin-top: 8px;
}
