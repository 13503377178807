/* Styles go here */

.axis path,
.axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

/* hide y axis */
.axis .tick line, .axis path {
    display: none;
}
.chart-area-circle {
    fill: #b8b8b8;
}

.center-circle {
    fill: #fff;
}

.gridlines {
    fill: none;
    stroke: #fff;
}

.minor {
    stroke-width: 1px
}
