@import "../../resources/scss/variables.scss";

.navbar {
  &.navbar-light {
    background-color: $navbar-bg;
    .navbar-nav {
      justify-content: flex-end;
      &.middle {
        justify-content: center;
      }
      .nav-item {
        .text-light {
          color: $menuItemColor $i;
          font-size: $menuItemSize;
        }
        .nav-link {
          @media (min-width: $breakpoint-desktop) {
            text-align: center;
          }
        }
        .dropdown {
          > button {
            line-height: 2;
            @media (max-width: $breakpoint-mobile) {
              padding-left: 0;
              padding-right: 0;
            }
          }
          .btn-secondary {
            background: transparent;
            border: 0;
            &:focus {
              box-shadow: none $i;
            }
          }
        }
        .dropdown-menu {
          min-width: 100%;
          overflow-y: auto;
          max-height: 550px;
          z-index: 99980;
        }
        &:hover {
          .text-light {
            color: lighten($color: $white, $amount: 5) $i;
          }
          background: lighten($color: $siteThemeColor, $amount: 5) $i;
          color: lighten($color: $white, $amount: 5) $i;
        }
      }
      &.flex-align {
        @media (max-width: $breakpoint-desktop) {
          align-items: flex-start;
        }
      }
      @media (max-width: $breakpoint-desktop) {
        flex-direction: column $i;
      }
    }
    .logo {
      /*width: 210px;*/
      max-height: 32px;
    }
    .navbar-brand {
      white-space: normal;
      word-break: break-all;
    }
  }
  .navbar-btn {
    display: none;
    border: none;
    &:hover,
    &:active {
      border: none $i;
      background: transparent $i;
      box-shadow: none $i;
    }

    .fa-bars {
      font-size: 25px;
      color: $white;
    }
    @media (max-width: $breakpoint-desktop) {
      display: block;
    }
  }
  // &.navbar-expand-sm {
  //   .navbar-collapse:not(.show) {
  //     @media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
  //       display: none $i;
  //     }
  //   }
  //   .navbar-collapse {
  //     @media (max-width: $breakpoint-desktop) {
  //       flex-direction: column;
  //       flex-basis: 100% $i;
  //       justify-content: flex-start;
  //       align-items: flex-start;
  //       padding-top: 10px;
  //     }
  //   }
  //   @media (max-width: $breakpoint-desktop) {
  //     justify-content: space-between $i;
  //     flex-flow: wrap;
  //   }
  // }
}

.dropdown-wrapper {
  padding: 0 $i;
  .dropdown-item {
    padding-top: 7px;
    padding-bottom: 7px;
    &.selected {
      background: $selected;
      color: $white;
    }
  }
  .dropdown-divider {
    margin: 0;
  }
}

.company-item {
  padding-left: 40px $i;
  &.default-company {
    background: $menuItemColor;
    &:hover {
      background: $menuItemColor;
    }
  }
}

.menu-dropdown {
  > button {
    color: $menuItemColor;
  }
}

.burger-menu {
  border: none $i;
  box-shadow: none $i;
  padding: 0;
  position: fixed;
  right: 10px;
  top: 100px;
  z-index: 9999;
  color: $siteThemeColor;
  &.btn-outline-secondary:not(:disabled):not(.disabled):active {
    border: none;
    color: $siteThemeColor;
    background: transparent;
  }
  &:hover {
    background: transparent;
    color: lighten($color: $siteThemeColor, $amount: 5);
  }
  &:focus {
    outline: none;
    box-shadow: none;
    background: transparent;
  }
  &:active {
    outline: none;
    box-shadow: none;
    background: transparent;
  }
}

.sticky-buttons {
  position: fixed;
  right: -1000px;
  z-index: 999;
  top: 100px;
  width: auto;
  transition: 0.3s all ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;
  height: calc(100% - 200px);
  &.toggled {
    right: 10px;
  }
  > button {
    // transform: rotate(-90deg);
    min-width: 110px;
    min-height: 35px;
    margin-bottom: 10px;
  }
}

.header{
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

