@import "../../resources/scss/variables.scss";

.sv_main {
  .sv_container {
    .sv_body {
      border-color: $siteThemeColor;

      .sv_p_root {
        .sv_page_title {
          font-size: 30px;
          font-weight: bold;

          &+div {
            margin-bottom: 20px;

            &>span {
              font-size: 16px;
            }
          }
        }

        .sv_p_title {
          padding: 0;
          margin-bottom: 5px;
          font-size: 20px;
        }

        table {
          td.sv_matrix_cell {
            .float-right {
              float: none $i;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          th{
            text-align: center;
          }
        }

        &>.sv_row {
          background-color: #f4f4f4;
          padding: 10px;
          margin-bottom: 20px;

          .sv_p_description {
            font-size: 15px;
            margin-bottom: 10px;
          }
        }

        .sv_q {
          padding: 0.5em 1em;
        }

        .ltd_small_teable_qstn {
          padding: 0 1em;

          table {
            box-shadow: none;
          }

          .sv_q_matrix_dropdown {
            input {
              text-align: right;
            }

            .sv_matrix_cell div {
              float: right;
            }
            .ltd_small_table_sum {
              font-weight: bolder;
              padding-right: 1.3em;
            }
            .ltd_small_table_sum_p {
              font-weight: bolder;
            }
          }
        }
      }
    }
  }

  button {
    background: var(--primary);

    &:hover {
      background: var(--primary);
    }
  }

  .sv_q_file_choose_button {
    background: var(--primary);
    color: $white;

    &:hover {
      background: var(--primary);
    }
  }
}


.popup {
  display: none;
  position: fixed;
  justify-content: center;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .popup-content {
    position: relative;
    // top: 30px;
    margin: auto 0;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
    display: flex;
    flex-direction: column;
  }

  .popup-innerwrap {
    min-width: 500px;
    max-width: 1000px;
    margin: 0 auto;
    width: auto;
  }

  .popup-header {
    padding: 1rem;
    color: #000;
    background: #fff;
    border-bottom: 1px solid #e9ecef;
  }

  .popup-body {
    padding: 1rem;
    background: #fff;
  }

  .popup-footer {
    padding: 1rem;
    color: #000;
    background: #fff;
    border-top: 1px solid #e9ecef;
    text-align: right;
  }

  /* Add Animation */
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }

    to {
      top: 0;
      opacity: 1;
    }
  }

  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

.sv_qstn {
  >div {
    position: relative;

    .sv_q_title {
      width: 80%;
    }

    .reference {
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      font-family: "Roboto Slab", serif;
    }
  }
}

.sv_q_matrix_dynamic {
  .sv_matrix_cell {
    text-align: center;

    .sv_matrix_dynamic_button {
      background: transparent;
      color: $siteThemeColor;
      padding: 0 !important;
      margin: 0;
      min-width: auto;
    }
  }
}
.footer{
  position: fixed;
  padding: 20px 0 0 0;
  margin: 20px 0 0 0;
  bottom: 0;
  width: 100%;
  max-width: 1110px;
  color: white;
  text-align: center;
  background-image: url(../../resources/images/background.JPG);
  background-color: #f0f0f0 !important;
  z-index: 1;
}

.sv_matrix_dynamic_button {
  min-width: 42px !important;
}
