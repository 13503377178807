@import "../../../../resources/scss/variables.scss";

.partner-view {
  .table-container {
    margin-top: 20px;
  }
  .table-container {
    text-align: center;
  }
  .partner-logo {
    background: $white;
    padding: 10px;
    @media (max-width: $breakpoint-tablet) {
      max-width: 50%;
    }
  }
  .upload-logo-container {
    background: $white;
    padding: 20px;
  }
}
