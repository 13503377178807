.table-borderless input {
    border: 0px;
    border-bottom: 2px solid rgba(0,0,0,0.3)
}

.modal table {
    margin-top: 1rem;

    tr:first-child td {
        border-top: 0px;
    }
}

.col-lg-6 {
    padding: 5px !important;

    > div {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 10px;
    }

    > div:nth-child(n+2) {
        margin-top: 10px;
    }
}
.btn-group, .btn-group-vertical {
    white-space: nowrap;
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
    white-space: nowrap;
}

.btn-group, .btn-group-vertical {
    white-space: nowrap;
}
