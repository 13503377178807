@import "../../../../resources/scss/variables.scss";
.lawTile {
  padding: 5px !important;
  display: flex !important;
  flex-direction: column;
  flex: 1;
  margin-right: 1rem!important;
  background-clip: border-box;
  border: 1px solid $borderColor;
  * {
    color: inherit;
  }
  &:hover {
    svg {
      color: darken($color: $white, $amount: 8);
    }
  }
  @media (max-width: $breakpoint-desktop) {
    padding: 10px;
  }
}

.tiles-container .lawTile:last-child {
  margin-right: 0;
}
