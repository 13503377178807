svg *::selection {
    background: transparent;
}

svg *::-moz-selection {
    background: transparent;
}

svg *::-webkit-selection {
    background: transparent;
}

rect.selection {
    stroke: #333;
    stroke-dasharray: 4px;
    stroke-opacity: 0.5;
    fill: transparent;
}

rect.cell-border {
    stroke: #eee;
    stroke-width: 0.3px;
}

rect.cell-selected {
    stroke: rgb(51,102,153);
    stroke-width: 0.5px;
}

rect.cell-hover {
    fill: #fff;
}

rect.bordered {
    stroke: rgba(64, 76, 87, 0.99);
    stroke-width: 2px;
}


text.text-selected {
    fill: #fff;
}


text.text-hover {
    fill: #fff;
}

#tooltip {
    position: absolute;
    width: auto;
    height: auto;
    pointer-events: none;
    line-height: 1;
    font-weight: bold;
    text-align: left;
    padding: 12px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 2px;
    pointer-events: none;
}

#tooltip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    position: absolute;
    pointer-events: none;
}

#tooltip.n:after {
    content: "\25BC";
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
}

#tooltip.hidden {
    display: none;
}

#tooltip p {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
}

.center {
    text-align:center;
}
.right {
    text-align:right;
}
text {
    font-family: 'Roboto Slab', serif;
}
.risk {
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
text.outline {
    font-weight: 700;
}
