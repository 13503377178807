.Map .node circle {
    fill: #999;
  }
  
  .Map .node text {
    font: 13px sans-serif;
  }
  
  .Map .node--internal circle {
    fill: #555;
  }
  
  .Map .node--internal text {
    text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
    /* font-weight: bold; */
  }

  .Map .link {
    fill: none;
    stroke: #555;
    stroke-opacity: 0.4;
    stroke-width: 1.5px;
  }  
  .Map {
    position: relative;
  }
  